import {
  TaskDispatchTypes,
  TaskT,
} from "../constants/ActionTypes/task-action-types";

export interface initialTaskState {
  task: TaskT[];
  deletedTasks: TaskT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  addStatus: boolean;
  total: number;
  search: any;
}

const defaultState = {
  task: [],
  deletedTasks: [],
  status: false,
  message: "",
  saveStatus: false,
  addStatus: false,
  total: 0,
  search: {
    title: "",
    question: "",
    book: ""
  }
};
const taskReducer = (
  state: initialTaskState = defaultState,
  action: TaskDispatchTypes
): initialTaskState => {
  switch (action.type) {
    case "TASKS_LOADING":
      return Object.assign({}, state, {
        task: [],
        status: false,
        message: "",
      });
    case "TASKS_LOADED":
      return Object.assign({}, state, {
        task: action.payload.tasks,
        total: action.payload.count,
        status: true,
        message: "",
      });
    case "TASKS_ERROR":
      return Object.assign({}, state, {
        task: [],
        status: false,
        message: action.payload,
      });
    case "TASK_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "TASK_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "TASK_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case "TASK_ADD_START":
      return Object.assign({}, state, {
        addStatus: false,
      });
    case "TASK_ADD_SUCCESS":
      return Object.assign({}, state, {
        addStatus: true,
      });
    case "TASK_ADD_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        addStatus: action.payload,
      });
    case "DELETED_TASKS_LOADING":
      return Object.assign({}, state, {
        deletedTasks: [],
        status: false,
        message: "",
      });
    case "DELETED_TASKS_LOADED":
      return Object.assign({}, state, {
        deletedTasks: action.payload.tasks,
        status: true,
        message: "",
      });
    case "DELETED_TASKS_ERROR":
      return Object.assign({}, state, {
        deletedTasks: [],
        status: false,
        message: action.payload,
      });
    case "TASK_CHANGE_SEARCH":
      const s = state.search || { title: ``, question: `` };
      (s as any)[action.payload.field] = action.payload.value;
      const n = {
        search: s
      };
      return Object.assign({}, state, n);
    default:
      return state;
  }
};

export default taskReducer;
