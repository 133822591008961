import { DeleteOutlined } from "@ant-design/icons";
import {Button, Popconfirm, Space, Table, TablePaginationConfig} from "antd";
import { UserT } from "../../constants/ActionTypes/login-action-types";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../store/store";
import UserActions from "../../actions/users";

export default function AdminTable({
  propAdmins,
  loaded,
  functionDeleteAdmin,
}: any) {
  const columns = [
    {
      title: "Név",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "E-mail cím",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: UserT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt az admint?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteAdmin(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const { total } = useSelector((state: RootStore) => state.usersReducer)
  const [current, setCurrent] = useState(1);
  const onChange = (pagination: TablePaginationConfig) => {
    setCurrent(pagination.current!);
    dispatch(UserActions.getAdmins(pagination.current!));
  }

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={propAdmins}
      loading={!loaded && !propAdmins}
      onChange={onChange}
      pagination={{
        position: ["bottomCenter"],
        pageSize: 10,
        current: current,
        total: total,
        showSizeChanger: false,
      }}
    />
  );
}
