import {
  NotificationDispatchTypes,
  NotificationT,
} from "../constants/ActionTypes/notification-action-types";

export interface initialNotificationState {
  notification: NotificationT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  total: number;
}

const defaultState: initialNotificationState = {
  notification: [],
  status: false,
  message: "",
  saveStatus: false,
  total: 0,
};

const notificationReducer = (
  state: initialNotificationState = defaultState,
  action: NotificationDispatchTypes
): initialNotificationState => {
  switch (action.type) {
    case "NOTIFICATIONS_LOADING":
      return Object.assign({}, state, {
        notification: [],
        status: false,
        message: "",
      });
    case "NOTIFICATIONS_LOADED":
      return Object.assign({}, state, {
        notification: action.payload.notifications,
        total: action.payload.count,
        status: true,
        message: "",
      });
    case "NOTIFICATIONS_ERROR":
      return Object.assign({}, state, {
        notification: [],
        status: false,
        message: action.payload,
      });
    case "NOTIFICATION_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "NOTIFICATION_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "NOTIFICATION_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case "PUSH_NOTIFICATION_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "PUSH_NOTIFICATION_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "PUSH_NOTIFICATION_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default notificationReducer;
