import {Button, Input, Popconfirm, Space, Switch, Table, TablePaginationConfig} from "antd";
import { BookT } from "../../../constants/ActionTypes/book-action-types";
import NoPicture from "../../../static/259987.png";
import type { ColumnType } from "antd/es/table";
import {useReducer, useRef, useState} from "react";
import type { InputRef } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import reducer, { initialState } from "../reducer";
import {
  useBarcodeChange,
  useBookTypeIdChange,
  useCreatorsChange,
  useDescriptionChange,
  useFileChange,
  useImageUrlChange,
  useKeywordsSelectedKeysChange,
  useKeywordsTargetKeysChange,
  useNameChange,
  usePurchaseUrlChange,
  useSearchedColumnChange,
  useSelectedIdChange,
} from "../bookUtils";
import { KeywordT } from "../../../constants/ActionTypes/keyword-action-types";
import {useDispatch, useSelector} from "react-redux";
import BookActions from "../../../actions/book";
import BookModal from "./bookModal";
import {RootStore} from "../../../store/store";
import UserActions from "../../../actions/users";

const BookTable = ({
  searchedColumn,
  width,
  showPageModal,
  functionDeleteBook,
  propBook,
  loaded,
  form,
  setModalVisible,
  setModify,
  getBooks,
  modalVisible,
  modify,
}: any) => {
  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof BookT;
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const {
    imageUrl,
    keywordsTargetKeys,
    file,
    name,
    description,
    creators,
    barcode,
    purchaseUrl,
    selectedId,
    booktypeId,
    keywordsSelectedKeys,
  } = state;
  const search = useSelector((state: RootStore) => state.bookReducer.search);
  const dispatch = useDispatch();
  const nameChange = useNameChange(reducerDispatch);
  const descriptionChange = useDescriptionChange(reducerDispatch);
  const creatorsChange = useCreatorsChange(reducerDispatch);
  const barcodeChange = useBarcodeChange(reducerDispatch);
  const imageUrlChange = useImageUrlChange(reducerDispatch);
  const searchedColumnChange = useSearchedColumnChange(reducerDispatch);
  const purchaseUrlChange = usePurchaseUrlChange(reducerDispatch);
  const selectedIdChange = useSelectedIdChange(reducerDispatch);
  const booktypeIdChange = useBookTypeIdChange(reducerDispatch);
  const keywordsTargetKeysChange = useKeywordsTargetKeysChange(reducerDispatch);
  const fileChange = useFileChange(reducerDispatch);
  const keywordsSelectedKeysChange =
    useKeywordsSelectedKeysChange(reducerDispatch);

  const showModifyModal = (record: BookT) => {
    form.setFieldsValue({
      name: record.title,
      description: record.description,
      page: record.pages,
      creators: record.creators,
      barcode: record.barcode,
      booktypes: record.type?.name,
      keyword: record.keywords,
      purchaseUrl: record.purchaseUrl,
    });
    setModalVisible(true);
    setModify(true);
    selectedIdChange(record.id);
    nameChange(record.title);
    descriptionChange(record.description);
    if (record.purchaseUrl) {
      purchaseUrlChange(record.purchaseUrl);
    }
    creatorsChange(record?.creators);
    barcodeChange(record?.barcode);
    booktypeIdChange(record?.type?.id);
    keywordsTargetKeysChange(
      record.keywords?.map((k: KeywordT) => k.id.toString())
    );
    imageUrlChange(
      process.env.REACT_APP_BASE_URL +
        "/files/content/pictures/" +
        record.imageUrl?.substring(
          `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`.length
        )
    );
    fileChange(record?.imageUrl);
  };

  const handleSearch = (
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    searchedColumnChange(dataIndex);
    setCurrent(1);
    dispatch(BookActions.getAllBook(undefined, 1))
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string
  ): ColumnType<BookT> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="searchPadding">
        <Input
          ref={searchInput}
          placeholder={`Keress ${title}`}
          value={search[dataIndex]}
          onChange={(e) => {
            dispatch({ type: "BOOKS_CHANGE_SEARCH", payload: { field: dataIndex, value: e.target.value } });
          }
          }
          onPressEnter={() => handleSearch(confirm, dataIndex)}
          className="searchInput"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            className="searchButton"
          >
            Keresés
          </Button>
          <Button
            onClick={() => {
              dispatch({ type: "BOOKS_CHANGE_SEARCH", payload: { field: dataIndex, value: `` } });
              handleSearch(confirm, dataIndex);
            }}
            size="small"
            className="searchButton"
          >
            Visszaállítás
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const publicate = async (record: BookT) => {
    await dispatch(BookActions.publicateBook(record.id));
    await getBooks();
  };

  const prioritization = async (record: BookT) => {
    await dispatch(BookActions.prioritizationBook(record.id));
    await getBooks();
  };

  const duplicate = async (record: BookT) => {
    await dispatch(BookActions.duplicateBook(record.id));
    await getBooks();
  };
  const onReset = () => {
    form.resetFields();
  };
  const closeModal = () => {
    setModalVisible(false);
    setModify(false);
    descriptionChange("");
    nameChange("");
    creatorsChange("");
    barcodeChange("");
    booktypeIdChange(null!);
    keywordsTargetKeysChange([]);
    fileChange(null!);
    imageUrlChange("");
    onReset();
  };

  const { total } = useSelector((state: RootStore) => state.bookReducer)
  const [current, setCurrent] = useState(1);
  const onChange = (pagination: TablePaginationConfig) => {
    setCurrent(pagination.current!);
    dispatch(BookActions.getAllBook(undefined, pagination.current!));
  }

  const columns = [
    {
      title: "Ikon",
      key: "bookCoverPic",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            {record.imageUrl ? (
              <img
                src={`${
                  process.env.REACT_APP_BASE_URL +
                  "/api/1.0.0/picture/getall/" +
                  record.imageUrl.substring(
                    `${process.env.REACT_APP_BASE_URL}/files/content/pictures/`
                      .length
                  )
                }`}
                alt="icon"
                className="coverPic"
              />
            ) : (
              <img src={NoPicture} alt="nopic" className="coverPic" />
            )}
          </Space>
        );
      },
    },
    {
      title: "Könyv címe",
      key: "bookName",
      dataIndex: "title",
      ...getColumnSearchProps("title", "címet"),
      sorter: (a: BookT, b: BookT) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Leírás",
      key: "bookDescription",
      ...getColumnSearchProps("description", "leírást"),
      render: (text: string, record: BookT) => {
        if (width > 1645) {
          const partString = record.description.substring(0, 200);
          let index = partString.indexOf(" ");
          index = partString.indexOf(" ", index + 180);
          const result = partString.substring(0, index);
          return record.description.length > 200
            ? result + "..."
            : record.description;
        } else {
          const partString = record.description.substring(0, 100);
          let index = partString.indexOf(" ");
          index = partString.indexOf(" ", index + 50);
          const result = partString.substring(0, index);
          return record.description.length > 100
            ? result + "..."
            : record.description;
        }
      },
      width: "30%",
    },
    {
      title: "Oldalszám",
      key: "bookPage",
      render: (text: string, record: BookT) => {
        const pages = record.pages?.length;
        return pages > 0 ? pages : 0;
      },
    },
    {
      title: "Készítők",
      key: "bookCreators",
      dataIndex: "creators",
      ...getColumnSearchProps("creators", "készítőt"),
    },
    {
      title: "Vonalkód",
      key: "bookBarcode",
      dataIndex: "barcode",
    },
    {
      title: "Publikálás",
      key: "publicate",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            <Switch
              onChange={() => publicate(record)}
              checked={record.publicated}
            />
          </Space>
        );
      },
    },
    {
      title: "Priorizálás",
      key: "priority",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            <Switch
              onChange={() => prioritization(record)}
              checked={record.priority}
            />
          </Space>
        );
      },
    },
    {
      title: "Duplikálás",
      key: "duplicate",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan duplikálni akarod ezt a könyvet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => duplicate(record)}
            >
              <Button>
                <CopyOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
    {
      title: "Oldalak",
      key: "pages",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showPageModal(record)}>
              <SnippetsOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Módosítás",
      key: "modify",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            <Button onClick={() => showModifyModal(record)}>
              <EditOutlined />
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Törlés",
      key: "delete",
      render: (text: string, record: BookT) => {
        return (
          <Space size="middle">
            <Popconfirm
              title="Biztosan törölni akarod ezt a könyvet?"
              okText="Igen"
              cancelText="Mégsem"
              onConfirm={() => functionDeleteBook(record)}
            >
              <Button type="primary" danger>
                <DeleteOutlined key="delete" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={propBook}
        loading={!loaded && !propBook}
        onChange={onChange}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          current: current,
          total: total,
          showSizeChanger: false,
        }}
        scroll={{ x: width / 2 }}
      />
      <BookModal
        imageUrlChange={imageUrlChange}
        imageUrl={imageUrl}
        file={file}
        fileChange={fileChange}
        keywordsTargetKeysChange={keywordsTargetKeysChange}
        keywordsTargetKeys={keywordsTargetKeys}
        name={name}
        description={description}
        creators={creators}
        barcode={barcode}
        purchaseUrl={purchaseUrl}
        selectedId={selectedId}
        booktypeId={booktypeId}
        keywordsSelectedKeys={keywordsSelectedKeys}
        keywordsSelectedKeysChange={keywordsSelectedKeysChange}
        nameChange={nameChange}
        descriptionChange={descriptionChange}
        creatorsChange={creatorsChange}
        barcodeChange={barcodeChange}
        purchaseUrlChange={purchaseUrlChange}
        booktypeIdChange={booktypeIdChange}
        modalVisible={modalVisible}
        modify={modify}
        setModalVisible={setModalVisible}
        setModify={setModify}
        getBooks={getBooks}
        form={form}
        closeModal={closeModal}
      />
    </div>
  );
};
export default BookTable;
