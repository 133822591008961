import {Pagination, TablePaginationConfig} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/store";
import {useState} from "react";
import BookActions from "../../../actions/book";
import PictureActions from "../../../actions/picture";

const PicturePaginator = ({
  onSearch,
  onPictureNameSearch,
  filteredPictures,
  propPictures,
  width,
    current,
    setCurrent,
}: any) => {
    const dispatch = useDispatch();
    const { total } = useSelector((state: RootStore) => state.pictureReducer)
    const onChange = (page: number) => {
        setCurrent(page!);
        dispatch(PictureActions.getAllPicture(page!));
    }

  return (
    <Pagination
      current={current}
      total={total}
      onChange={onChange}
      showSizeChanger={false}
    />
  );
};
export default PicturePaginator;
