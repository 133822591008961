import { UserT } from "../constants/ActionTypes/login-action-types";
import { UserDispatchTypes } from "../constants/ActionTypes/user-action-types";

export interface initialUserState {
  admins: UserT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  total: number;
}

const defaultState = {
  admins: null!,
  status: false,
  message: "",
  saveStatus: false,
  total: 0
};

const usersReducer = (
  state: initialUserState = defaultState,
  action: UserDispatchTypes
): initialUserState => {
  switch (action.type) {
    case "USER_ADMIN_LOADING":
      return Object.assign({}, state, {
        admins: [],
        status: false,
        message: "",
      });
    case "USER_ADMIN_LOADED":
      return Object.assign({}, state, {
        admins: action.payload.admins,
        total: action.payload.count,
        status: true,
        message: "",
      });
    case "USER_ADMIN_ERROR":
      return Object.assign({}, state, {
        admins: [],
        status: false,
        message: action.payload,
      });
    case "USER_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "USER_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "USER_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default usersReducer;
