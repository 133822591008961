import { message, Row, Col, Button, Form, Modal, Input, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  RestOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AudioActions from "../../../actions/audio";

import { RootStore } from "../../../store/store";
import { AudioT } from "../../../constants/ActionTypes/audio-action-types";
import BookActions from "../../../actions/book";
import "../../formating/content.css";
import DeletedAudios from "./deletedAudios";
import AudioTable from "./table";

export const downloadContent = async (record: AudioT) => {
  message.info("A letöltés folyamatban, kérem várjon!");
  fetch(
    `${process.env.REACT_APP_BASE_URL}/api/1.0.0/audio/download/${record.filename}`,
    {
      method: "GET",
      headers: { "Content-Type": "audio/mpeg" },
    }
  )
    .then((response) => response.blob())
    .then((blob) => {
      const newUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = newUrl;
      link.setAttribute(
        "download",
        record.originalName ? record.originalName : "file.mp3"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch((err) => {
      console.log(err);
      message.error("Hiba a letöltés közben!");
    });
};

export default function Audio() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const effectRan = useRef(false);
  const propAudio = useSelector((state: RootStore) => state.audioReducer.audio);
  const status = useSelector((state: RootStore) => state.audioReducer.status);
  const [modalVisible, setModalVisible] = useState<boolean>(() => false);
  const [loaded, setLoaded] = useState<boolean>(() => false);
  const [filteredAudios, setFilteredAudios] = useState<AudioT[]>(() => []);
  const [onSearch, setOnSerach] = useState<boolean>(() => false);
  const [current, setCurrent] = useState(1);
  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(
    () => false
  );
  const search = useSelector((state: RootStore) => state.audioReducer.search);
  const [updated, setUpdated] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (effectRan.current === false) {
        try {
          await dispatch(AudioActions.getAllAudio());
          await dispatch(BookActions.getAllBook(true));
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchData();
    if (status) {
      setLoaded(true);
    }
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, status]);

  const getAudios = async () => {
    setLoaded(false);
    try {
      await dispatch(AudioActions.getAllAudio());
      if (status) {
        if (propAudio.length > 0) {
          setLoaded(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const searchAudios = () => {
    setOnSerach(true);
    setCurrent(1);
    dispatch(AudioActions.getAllAudio());
  };

  const resetFilter = () => {
    setOnSerach(false);
    setCurrent(1);
    dispatch({ type: "AUDIO_CHANGE_SEARCH", payload: { field: `book`, value: "" } });
    dispatch(AudioActions.getAllAudio());
  };

  const closeDeletedAudiosModal = async () => {
    setShowDeletedModal(false);
    await getAudios();
  };

  return (
    <div className="content">
      <Row>
        <Col span={24} className="colPadding">
          <h1 className="title">
            <strong>Audiók</strong>
          </h1>
          <Row>
            <Row>
              <Col span={30} className="colPadding">
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  Létrehozás
                </Button>
              </Col>
            </Row>
            <div className="deletedModal">
              <Button onClick={() => setShowDeletedModal(true)}>
                <RestOutlined />
              </Button>
            </div>
          </Row>
          <Space className="colPadding">
            Könyv alapú keresés:
            <Input
              name="booksearch"
              value={search.book}
              onChange={(e) => {
                dispatch({ type: "AUDIO_CHANGE_SEARCH", payload: { field: `book`, value: e.target.value } })
                setUpdated(new Date().getTime());
              }}
            />
            {!onSearch ? (
              <Button type="primary" size="small" onClick={searchAudios}>
                <SearchOutlined />
              </Button>
            ) : (
              <Button type="primary" size="small" onClick={resetFilter}>
                <RetweetOutlined />
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <AudioTable
        form={form}
        getAudios={getAudios}
        setModalVisible={setModalVisible}
        propAudio={propAudio}
        loaded={loaded}
        filteredAudios={filteredAudios}
        onSearch={onSearch}
        modalVisible={modalVisible}
        current={current}
        setCurrent={setCurrent}
      />
      <Modal
        visible={showDeletedModal}
        onCancel={closeDeletedAudiosModal}
        maskClosable={true}
        forceRender
        footer={false}
        width={"87.5vw"}
        bodyStyle={{ height: "89.5vh" }}
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          marginTop: 0,
        }}
      >
        <DeletedAudios showDeletedModal={showDeletedModal} />
      </Modal>
    </div>
  );
}
