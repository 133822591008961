import {
  BookT,
  BookDispatchTypes,
} from "../constants/ActionTypes/book-action-types";

export interface initialBookState {
  book: BookT[];
  deletedBooks: BookT[];
  status: boolean;
  message: string;
  saveStatus: boolean;
  total: number;
  search: any;
}

const defaultState: initialBookState = {
  book: [],
  deletedBooks: [],
  status: false,
  message: "",
  saveStatus: false,
  total: 0,
  search: {
    title: "",
    description: "",
    creators: ""
  }
};

const bookReducer = (
  state: initialBookState = defaultState,
  action: BookDispatchTypes
): initialBookState => {
  switch (action.type) {
    case "BOOKS_LOADING":
      return Object.assign({}, state, {
        book: [],
        status: false,
        message: "",
      });
    case "BOOKS_LOADED":
      return Object.assign({}, state, {
        book: action.payload.books,
        total: action.payload.count,
        status: true,
        message: "",
      });
    case "BOOKS_ERROR":
      return Object.assign({}, state, {
        book: [],
        status: false,
        message: action.payload,
      });
    case "DELETED_BOOKS_LOADING":
      return Object.assign({}, state, {
        deletedBooks: [],
        status: false,
        message: "",
      });
    case "DELETED_BOOKS_LOADED":
      return Object.assign({}, state, {
        deletedBooks: action.payload.books,
        status: true,
        message: "",
      });
    case "DELETED_BOOKS_ERROR":
      return Object.assign({}, state, {
        deletedBooks: [],
        status: false,
        message: action.payload,
      });
    case "BOOK_REGISTER_START":
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case "BOOK_REGISTER_SUCCESS":
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case "BOOK_REGISTER_ERROR":
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });
    case "BOOKS_CHANGE_SEARCH":
      const s = state.search;
      (s as any)[action.payload.field] = action.payload.value;
      const n = {
        search: s
      };
      return Object.assign({}, state, n);
    default:
      return state;
  }
};

export default bookReducer;
